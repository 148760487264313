import React, { Component, Fragment } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Button } from 'reactstrap';

import trackEvent from '../components/analytics';
import Layout, {
  Player,
  ViewButtonNext,
  ViewButtonPrevious,
  ShareButtons,
} from '../components/layout';
import SEO from '../components/seo';

import Background from '../images/landscape-1.jpg';
import Background2 from '../images/quizz-correction.jpg';

import Question1 from '../audio/di/voixoff_DI_34.mp3';
import Question1Correct from '../audio/di/voixoff_DI_35.mp3';

import Question2 from '../audio/di/voixoff_DI_36.mp3';
import Question2Correct from '../audio/di/voixoff_DI_37.mp3';

import Question3 from '../audio/di/voixoff_DI_38.mp3';
import Question3Correct from '../audio/di/voixoff_DI_39.mp3';

import data from '../quizz/quizz-3.json';

const ViewArticle = styled.article`
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
  justify-items: center;
  align-items: center;

  max-width: 100%;
  background-image: ${(props) =>
    props.correction ? `url(${Background2})` : `url(${Background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  overflow-y: auto;
  padding-top: 2rem;
`;

const ViewForm = styled.form`
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  padding: 1rem 4rem 5rem 6rem;
  align-content: flex-start;
  line-height: 1.2;
  font-size: 1.1rem;
  animation: bounceIn;
  animation-duration: 1s;
`;

const ViewLabel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem 0 0.1rem 0;
  text-align: left;
  font-weight: bold;

  p {
    font-size: 1.2rem;
    font-family: 'Roboto Slab' !important;
    line-height: 1.2;
    padding-right: 1rem;
  }
`;

const ViewAnswer = styled.label`
  display: flex;
  align-items: center;
  border: 3px solid #ddd;
  background: #fff;
  border-radius: 10px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  ${(props) => (props.correction ? '' : 'cursor: pointer;')}
  margin: 0.1rem 0 0.1rem 0;
  text-align: left;

  ${(props) => props.selected && 'border-color: #17a2b8;'}

  ${(props) => props.correct && 'border-color: #28a745;'}

  ${(props) => props.incorrect && 'border-color: #dc3545;'}

  &:hover {
    ${(props) => (props.correction ? '' : 'border: 3px solid #bfd6e7;')}
  }
`;

const ViewChoice = styled.div`
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  line-height: 1.4;
  background: #28a745;
  border-radius: 100px;
  text-align: center;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: #fff;
`;

const ViewPlayerQuestions = styled.div`
  margin-bottom: 1rem;
`;

const ViewButton = styled(Button)`
  margin-top: 1rem;
`;

class Part3_QuizzForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      correction: false,

      answer_content_1: 'N/A',
      answer_correct_1: false,
      answer_selected_1: 0,

      answer_content_2: 'N/A',
      answer_correct_2: false,
      answer_selected_2: 0,

      answer_content_3: 'N/A',
      answer_correct_3: false,
      answer_selected_3: 0,

      timestamp: Date.now(),
    };
    this.onChangeAnswer1 = this.onChangeAnswer1.bind(this);
    this.onChangeAnswer2 = this.onChangeAnswer2.bind(this);
    this.onChangeAnswer3 = this.onChangeAnswer3.bind(this);
    this.addQuizz = this.addQuizz.bind(this);
  }

  onChangeAnswer1 = (e) => {
    this.setState({
      answer_correct_1: e.target.dataset.correct === 'yes' ? true : false,
      answer_content_1: e.target.value,
      answer_selected_1: e.target.dataset.selected,
    });
  };

  onChangeAnswer2 = (e) => {
    this.setState({
      answer_correct_2: e.target.dataset.correct === 'yes' ? true : false,
      answer_content_2: e.target.value,
      answer_selected_2: e.target.dataset.selected,
    });
  };

  onChangeAnswer3 = (e) => {
    this.setState({
      answer_correct_3: e.target.dataset.correct === 'yes' ? true : false,
      answer_content_3: e.target.value,
      answer_selected_3: e.target.dataset.selected,
    });
  };

  addQuizz = () => {
    var myQuizzToTop = document.getElementById('content');
    myQuizzToTop.scrollTop = 0;

    var myHeaders = new Headers();
    myHeaders.append('x-api-key', process.env.GATSBY_APPSYNC_KEY);
    myHeaders.append('Content-Type', 'application/json');

    var graphqlQuery1 = JSON.stringify({
      query: `mutation createQuizAnswer ($inputData: CreateQuizAnswerInput!) {
        createQuizAnswer (input: $inputData) {
        id
        quiz_id
        question_id
        answer_content
        answer_correct
        timestamp
        }
       }`,
      variables: {
        inputData: {
          quiz_id: 'Quizz_3',
          question_id: 'Question_1',
          answer_content: this.state.answer_content_1,
          answer_correct: this.state.answer_correct_1,
          timestamp: this.state.timestamp,
        },
      },
    });

    var graphqlQuery2 = JSON.stringify({
      query: `mutation createQuizAnswer ($inputData: CreateQuizAnswerInput!) {
        createQuizAnswer (input: $inputData) {
        id
        quiz_id
        question_id
        answer_content
        answer_correct
        timestamp
        }
       }`,
      variables: {
        inputData: {
          quiz_id: 'Quizz_3',
          question_id: 'Question_2',
          answer_content: this.state.answer_content_2,
          answer_correct: this.state.answer_correct_2,
          timestamp: this.state.timestamp,
        },
      },
    });

    var graphqlQuery3 = JSON.stringify({
      query: `mutation createQuizAnswer ($inputData: CreateQuizAnswerInput!) {
        createQuizAnswer (input: $inputData) {
        id
        quiz_id
        question_id
        answer_content
        answer_correct
        timestamp
        }
       }`,
      variables: {
        inputData: {
          quiz_id: 'Quizz_3',
          question_id: 'Question_3',
          answer_content: this.state.answer_content_3,
          answer_correct: this.state.answer_correct_3,
          timestamp: this.state.timestamp,
        },
      },
    });

    var requestOptions1 = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery1,
      redirect: 'follow',
    };

    var requestOptions2 = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery2,
      redirect: 'follow',
    };

    var requestOptions3 = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery3,
      redirect: 'follow',
    };

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions1).then((response) =>
      response.text(),
    );
    // .then((result) => console.log(result))
    // .catch((error) => console.log('error', error));

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions2).then((response) =>
      response.text(),
    );
    // .then((result) => console.log(result))
    // .catch((error) => console.log('error', error));

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions3).then((response) =>
      response.text(),
    );
    // .then((result) => console.log(result))
    // .catch((error) => console.log('error', error));

    this.setState({
      correction: true,
    });
  };

  render() {
    return (
      <Layout location={typeof window !== 'undefined' ? location : null}>
        <SEO pathname="/di/part-3-quizz-form" />
        <ViewArticle id="content" correction={this.state.correction == true && true}>
          <ViewForm>
            {data.questions.map((item, indexQuestion) => {
              return (
                <Fragment key={indexQuestion}>
                  <ViewLabel>
                    <p>{item.question}</p>
                    <ViewPlayerQuestions>
                      {indexQuestion === 0 && (
                        <Player
                          isQuizz
                          sound={
                            this.state.correction == true
                              ? Question1Correct
                              : Question1
                          }
                        />
                      )}
                      {indexQuestion === 1 && (
                        <Player
                          isQuizz
                          sound={
                            this.state.correction == true
                              ? Question2Correct
                              : Question2
                          }
                        />
                      )}
                      {indexQuestion === 2 && (
                        <Player
                          isQuizz
                          sound={
                            this.state.correction == true
                              ? Question3Correct
                              : Question3
                          }
                        />
                      )}
                    </ViewPlayerQuestions>
                    {item.answers.map((answer, index) => {
                      return (
                        <Fragment key={index}>
                          {this.state.correction != true ? (
                            <Fragment>
                              {indexQuestion === 0 && (
                                <ViewAnswer
                                  htmlFor={answer.id}
                                  selected={
                                    this.state.answer_selected_1 == index + 1 && true
                                  }
                                >
                                  <input
                                    onChange={this.onChangeAnswer1}
                                    name={item.id}
                                    id={answer.id}
                                    value={answer.answer}
                                    type="radio"
                                    data-correct={answer.correct}
                                    data-selected={index + 1}
                                  />
                                  <ViewChoice>{answer.choice}</ViewChoice>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 1 && (
                                <ViewAnswer
                                  htmlFor={answer.id}
                                  selected={
                                    this.state.answer_selected_2 == index + 1 && true
                                  }
                                >
                                  <input
                                    onChange={this.onChangeAnswer2}
                                    name={item.id}
                                    id={answer.id}
                                    value={answer.answer}
                                    type="radio"
                                    data-correct={answer.correct}
                                    data-selected={index + 1}
                                  />
                                  <ViewChoice>{answer.choice}</ViewChoice>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 2 && (
                                <ViewAnswer
                                  htmlFor={answer.id}
                                  selected={
                                    this.state.answer_selected_3 == index + 1 && true
                                  }
                                >
                                  <input
                                    onChange={this.onChangeAnswer3}
                                    name={item.id}
                                    id={answer.id}
                                    value={answer.answer}
                                    type="radio"
                                    data-correct={answer.correct}
                                    data-selected={index + 1}
                                  />
                                  <ViewChoice>{answer.choice}</ViewChoice>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                            </Fragment>
                          ) : (
                            <Fragment>
                              {indexQuestion === 0 && (
                                <ViewAnswer
                                  correct={answer.correct === 'yes' && true}
                                  incorrect={
                                    answer.correct != 'yes' &&
                                    this.state.answer_selected_1 == index + 1 &&
                                    true
                                  }
                                  correction
                                >
                                  <input type="radio" disabled />
                                  <ViewChoice>{answer.choice}</ViewChoice>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 1 && (
                                <ViewAnswer
                                  correct={answer.correct === 'yes' && true}
                                  incorrect={
                                    answer.correct != 'yes' &&
                                    this.state.answer_selected_2 == index + 1 &&
                                    true
                                  }
                                  correction
                                >
                                  <input type="radio" disabled />
                                  <ViewChoice>{answer.choice}</ViewChoice>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 2 && (
                                <ViewAnswer
                                  correct={answer.correct === 'yes' && true}
                                  incorrect={
                                    answer.correct != 'yes' &&
                                    this.state.answer_selected_3 == index + 1 &&
                                    true
                                  }
                                  correction
                                >
                                  <input type="radio" disabled />
                                  <ViewChoice>{answer.choice}</ViewChoice>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      );
                    })}
                  </ViewLabel>
                </Fragment>
              );
            })}
            {this.state.correction != true && (
              <ViewButton
                color="success"
                onClick={() => {
                  this.addQuizz();
                  trackEvent(
                    'Quizz Page Part 3',
                    'User saw quizz correction',
                    'yes',
                  );
                }}
              >
                <i className="fa fa-check" /> Valider
              </ViewButton>
            )}
          </ViewForm>
          <Link to="/di/part-3-quizz">
            <ViewButtonPrevious color="warning">
              <i className="fa fa-chevron-left fa" />
            </ViewButtonPrevious>
          </Link>
          {this.state.correction == true && (
            <Link to="/di/part-3-success">
              <ViewButtonNext
                color="warning"
                onClick={() => {
                  localStorage.setItem('Step3Complete', 'yes');
                }}
              >
                <i className="fa fa-chevron-right" />
              </ViewButtonNext>
            </Link>
          )}
        </ViewArticle>
        <ShareButtons />
      </Layout>
    );
  }
}

export default Part3_QuizzForm;
